<template>
  <!-- 订单选项卡 -->
  <div class="buying-order">
    <XtxTabs v-model:active="active">
      <XtxTabTitle v-for="status in buyingStatus" :key="status.name">
        {{ status.label }}
      </XtxTabTitle>
    </XtxTabs>
    <div class="body">
      <div class="column goods">
        <Select v-model="model" style="width: 170px">
          <Option
            v-for="item in selectTimeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </Option>
        </Select>
        <span style="margin-left: 40px">求购单详情</span>
      </div>
      <div class="column state">总金额</div>
      <div class="column amount">订单状态</div>
      <!-- <div class="column state">日期</div> -->
      <div class="column action">操作</div>
    </div>
    <!-- 订单列表 -->
    <div v-if="orderList" class="order-list">
      <!-- <div v-if="loading" class="loading"></div> -->
      <template v-if="orderList && orderList.rows">
        <BuyingItem
          v-for="item in orderList.rows"
          :key="item.id"
          :order="item"
          @onCancelOrder="onCancelOrder"
          @onReloadOrderList="getData"
          @onViewLogistics="onViewLogistics"
        />
      </template>
      <div v-if="orderList?.rows?.length === 0" class="none">暂无数据</div>
      <!-- <XtxPagination
        v-if="orderList && totalPage > 1"
        v-model:page="reqParams.page"
        :pageSize="reqParams.pageSize"
        :count="totalCount"
      /> -->
      <div style="float: right" class="table-page">
        <Page :total="orderList.records" @on-change="onChange" />
      </div>
    </div>
  </div>
</template>

<script>
import BuyingItem from '@/views/member/order/buying/components/BuyingItem'
import { buyingStatus } from '@/api/constants'
import VueEvent from '@/model/event'
import { ref } from 'vue'
export default {
  name: 'OrderListPage',
  components: { BuyingItem },
  data () {
    return {
      active: 0,
      model: '',
      selectTimeList: [
        {
          value: '0',
          label: '全部',
        },
        {
          value: '3',
          label: '三个月',
        },
        {
          value: '12',
          label: '近1年',
        },
      ],
      orderList: {},
      numPage: 1, // 当前页号
      currentStatu: '',
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.delPreOrderGoodsMitt()
    this.cancelPreOrdeGoodsMitt()
    this.buyingOrderSrarchMitt()
  },
  methods: {
    init () {
      this.pageData(1)
    },
    pageData (num, status = '') {
      const params = {
        // page: num,
        // rows: 10,
        // formate: '',
        // status,
        // companyName: '',
        // categoryId: '',
        // sidx: 'create_date',
        // sord: 'desc',

        page: num,
        rows: 10,
        productName: '',
        formate: '',
        companyName: '',
        categoryId: '',
        beginDate: '',
        endDate: '',
        status,
        sidx: 'create_date',
        sord: 'desc',
      }
      this.$store.dispatch('PreOrderGoodsListWithToken', params).then((res) => {
        if (res.returnCode === '1') {
          this.orderList = res.result
        }
      })
    },
    onChange (val) {
      this.pageData(val, this.currentStatus)
    },
    delPreOrderGoodsMitt () {
      VueEvent.on('delPreOrderGoodsMitt', (value) => {
        if (value) {
          this.pageData(this.numPage)
        }
      })
    },
    cancelPreOrdeGoodsMitt () {
      VueEvent.on('cancelPreOrdeGoodsMitt', (value) => {
        if (value) {
          this.pageData(this.numPage)
        }
      })
    },
    buyingOrderSrarchMitt () {
      VueEvent.on('buyingOrderSrarchMitt', (value) => {
        if (value) {
          const postData = ref({
            page: 1,
            rows: 10,
            productName: '',
            formate: '',
            companyName: '',
            categoryId: '',
            beginDate: value.beginTime,
            endDate: value.endTime,
            status: 1,
            sidx: 'create_date',
            sord: 'desc',
          })
          this.$store
            .dispatch('PreOrderGoodsListWithToken', postData.value)
            .then((response) => {
              this.orderList = response.result
            })
            .catch(() => {})
        }
      })
    },
  },
  watch: {
    active (val) {
      switch (val) {
        case 1:
          // 待报价
          this.currentStatus = 1
          this.pageData(this.numPage, 1)
          break
        case 2:
          // 待确认
          this.currentStatus = 2
          this.pageData(this.numPage, 2)
          break
        case 3:
          // 已取消
          this.currentStatus = 3
          this.pageData(this.numPage, 3)
          break
        case 4:
          // 已完成
          this.currentStatus = 5
          this.pageData(this.numPage, 5)
          break
        default:
          this.currentStatus = ''
          this.pageData(this.numPage, '')
          break
      }
    },
    model (val) {
      if (Number(val) === 0) {
        this.postData.beginDate = ''
        this.pageData()
        return
      }
      // x月前时间 的订单
      const nowdate = new Date()
      nowdate.setMonth(nowdate.getMonth() - val)
      const y = nowdate.getFullYear()
      const m = nowdate.getMonth() + 1
      const d = nowdate.getDate()
      const retrundate = y + '-' + m + '-' + d
      this.postData.beginDate = retrundate
      this.pageData()
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup () {
    return {
      buyingStatus,
    }
  },
}
</script>

<style lang="less" scoped>
.buying-order {
  height: 100%;
  background: #fff;
  margin-top: 20px;
  position: relative;
}

.order-list {
  background: #fff;
  padding-top: 10px;
  // padding: 20px;
  position: relative;
}

.loading {
  height: calc(100% - 60px);
  width: 100%;
  min-height: 400px;
  position: absolute;
  left: 0;
  top: 60px;
  // background: rgba(255, 255, 255, 0.9) url(../../../assets/images/loading.gif)
  //   no-repeat center 18%;
}

.none {
  height: 400px;
  text-align: center;
  line-height: 400px;
  color: #999;
  background: rgba(255, 255, 255, 0.9);
}
.body {
  display: flex;
  align-items: stretch;
  background: #f5f5f5;
  .column {
    border-left: 1px solid #f5f5f5;
    text-align: center;
    padding: 20px;
    > p {
      padding-top: 10px;
    }
    &:first-child {
      border-left: none;
    }
    &.goods {
      flex: 1;
      padding: 10;
      align-self: center;
      text-align: left;
    }
    &.state {
      width: 120px;
      .green {
        color: @xtxColor;
      }
    }
    &.amount {
      width: 200px;
      .red {
        color: @priceColor;
      }
    }
    &.action {
      width: 140px;
      a {
        display: block;
        &:hover {
          color: @xtxColor;
        }
      }
    }
  }
}
</style>
