<template>
  <div class="buying-page">
    <BuyingPanel title="我的求购单">

      <BuyingSearch />
      <!-- 商品筛选组件 -->
      <!-- <SubFilter /> -->
      <!-- 订单列表 -->
      <BuyingListPage />
    </BuyingPanel>
  </div>
</template>

<script>
import BuyingPanel from '@/views/member/order/buying/components/BuyingPanel'
// import SubFilter from '@/views/member/merchandise/components/SubFilter'
import BuyingSearch from '@/views/member/order/buying/components/BuyingSearch'
import BuyingListPage from '@/views/member/order/buying/BuyingListPage'
import useOrderList from '@/hooks/member/useOrderList'
export default {
  name: 'BuyingPage',
  components: {
    BuyingPanel,
    BuyingSearch,
    // SubFilter,
    BuyingListPage,
  },
  data () {
    return {
      formItem: {
        search: '',
      },

    }
  },
  setup () {
		 const {
      active,
      // orderList,

    } = useOrderList()
		 return {
      active,

    }
	},
}
</script>

<style lang="less" scoped>
.buying-page {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
</style>
