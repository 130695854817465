<template>
  <div class="buying-panel">
    <div class="header">
      <h4>{{ title }}</h4>
    </div>
    <div class="goods-list">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BuyingPanel',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {},
}
</script>
<style scoped lang="less">
.buying-panel {
  background-color: #fff;
  padding: 0 20px;
  margin-top: 20px;
  min-height: 400px;
  // border: 1px solid #dcdfe6;
  .header {
    height: 66px;
    border-bottom: 2px solid #f5f5f5;
    padding: 18px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .btn {
      display: block;
      width: 94px;
      height: 40px;
      color: #fff;
      background: #d12b23;
      text-align: center;
      line-height: 40px;
      border: 1px solid #d12b23;
    }
    h4 {
      font-size: 22px;
      font-weight: normal;
    }
  }
  .goods-list {
    // display: flex;
    // justify-content: space-around;
    // padding-top: 20px;
  }
}
</style>
