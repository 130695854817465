<template>
  <!-- 概览 -->
  <div class="merchandise-search">
    <div class="search">
      <div class="search-input">
        <label for="">日期</label>
        <DatePicker
          type="daterange"
          split-panels
          placeholder="选择日期 - 至"
          style="width: 200px"
          @on-change="onChange"
        ></DatePicker>
      </div>
      <Button class="btn" @click="search()">查询</Button>
    </div>
  </div>
</template>
<script>
import VueEvent from '@/model/event'
export default {
  name: 'MerchandiseSearch',
  data () {
    return {
      searchInfo: {
        beginTime: '',
        endTime: '',
      },
    }
  },
  methods: {
    search () {
      VueEvent.emit('buyingOrderSrarchMitt', this.searchInfo)
    },
    onChange (val) {
      this.searchInfo.beginTime = val[0]
      this.searchInfo.endTime = val[1]
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
}
</script>
<style scoped lang="less">
.merchandise-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .search {
    display: flex;
    margin-top: 20px;
    .search-input {
      // margin-right: 20px;
      display: flex;
      align-items: center;

      label {
        width: 40px;
        // text-align: right;
        // padding-right: 10px;
        box-sizing: border-box;
      }
    }
  }
  .btn {
    // display: block;
    width: 82px;
    background: #d12b23;
    height: 36px;
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/deep/ .ivu-input {
  border: 1px solid #dcdee2 !important;
  padding: 4px 7px !important;
}
</style>
